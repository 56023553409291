import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { TinaMarkdown } from "tinacms/dist/rich-text";
import { H1, H2, H3 } from '../components/tinaCMSHeading';
import Paragraph from '../components/tinaCMSParagraph';
import Image from '../components/tinaCMSImage';
import { UnorderedList, ListItem } from '../components/tinaCMSList';
import "../styles/markdown-styles.css"

// Custom component to render iframes
const Iframe = ({ src, title }) => {
  return (
    <div className="iframe-container">
      <iframe
        src={src}
        title={title}
        frameBorder="0"
        allowFullScreen
        className="w-full h-96"
      ></iframe>
    </div>
  );
};

const BlogPostTemplate = ({
  data: { site, markdownRemark: post, previous, next },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`
  const pathUrl = `${site.siteMetadata?.siteUrl}${location.pathname}`

  return (
    <Layout location={location} title={siteTitle} classNamer="">
      
      {/* <nav className="CyGroteskGrandMedium px-5 lg:px-0">
        <div className="flex justify-between items-center">
          {previous && (
            <Link to={previous.fields.slug} rel="prev" className="flex items-center">
              <span className="pr-5">←&nbsp;</span> {previous.frontmatter.title}
            </Link>
          )}
          {next && (
            <Link to={next.fields.slug} rel="next" className="flex items-center">
              {next.frontmatter.title} <span className="pl-5">&nbsp;→</span>
            </Link>
          )}
        </div>
      </nav> */}
      
      <div className="">
        {post.frontmatter.imgSrc && (
          <a href={post.frontmatter.website} target="_blank" rel="nofollow">
            <img
              src={post.frontmatter.imgSrc}
              alt={post.frontmatter.title}
              className="w-full"
            />
          </a>
        )}
      </div>

      <div className="md:w-9/12 mx-auto px-5 lg:py-10 my-10">
        <article
          className="articleBody"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="mb-8 md:mb-20 lg:mb-5">
            <h1 itemProp="headline" className="CyGroteskGrandBold text-lg">{post.frontmatter.title}</h1>
            {post.frontmatter.website && (
              <p className="text-sm leading-5"><a href={post.frontmatter.website} target="_blank" rel="nofollow">{post.frontmatter.website}</a></p>
            )}
          </header>

          {/* Render markdown as HTML (including iframes) */}
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
            className="markdown-content text-sm leading-5"
          />

          {/* Render TinaMarkdown for additional content */}
          <TinaMarkdown
            content={post.rawMarkdownBody}
            components={{
              h1: H1,
              h2: H2,
              h3: H3,
              p: Paragraph,
              ul: UnorderedList,
              li: ListItem,
              img: Image,
              iframe: Iframe,  // This will handle iframes if present in raw markdown
            }}
          />

        </article>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 270)
      rawMarkdownBody
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        imgSrc
        website
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`